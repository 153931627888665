<template>
  <div class="card-icon" :style="style">
    <img :style="style" v-if="type === 'visa'" :src="require('@/assets/products/svg/Visa.svg')" />
    <img :style="style" v-else-if="type === 'mastercard'" :src="require('@/assets/products/svg/Mastercard.svg')" />
    <img :style="style" v-else-if="type === 'amex'" :src="require('@/assets/products/svg/Amex.svg')" />
  </div>
</template>

<script>
export default {
  name: 'CardIcon',
  props: ['type', 'width', 'height'],
  computed: {
    style() {
      return `width: ${this.width}; height: ${this.height};`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-icon {
  background: #F1F1F1;

  img {
    object-fit: contain;
  }
}
</style>