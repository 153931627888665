<template>
  <LayoutVendor>
    <template v-slot:title>SUBSCRIPTION</template>
    <div class="subscriptions-page p-3">
      <template v-if="subscriptionHistory.length > 0 && currentBill && currentPaymentMethod">
        <h3>CURRENT SUBSCRIPTION</h3>

        <div class="row">
          <div class="col-6">
            <div class="card left">
              <div class="content">
                <div class="d-flex justify-content-between">
                  <div>
                    <div class="d-flex align-items-center">
                      <div>
                        <span class="title-sm black">{{ currentBill.name }}</span>
                      </div>
                      <div>
                        <span class="pill ml-2">MONTHLY</span>
                      </div>
                    </div>
                    <div>
                      <span class="text-xs muted">{{ currentBill.description }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="price title-sm black">
                      ${{ currentBill.price }}
                      <span class="text-xs muted">/month</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column mt-3">
                  <div class="d-flex w-100 justify-content-between align-items-center">
                    <div>
                      <span class="title-xs">{{ currentUser.company.users_count }} of {{ currentBill.max_user }} Members</span>
                    </div>
                    <div>
                      <AppBtn @click="$router.push({ path: '/company/member' })" text class="text-primary px-0">Add Member</AppBtn>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" :style="`width: ${memberPercentage}%`" :aria-valuenow="memberPercentage" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div class="d-flex justify-content-end">
                  <AppBtn @click="subscriptionModal = true" text class="text-primary px-0" prepend-icon="arrow_up_right">See subscription tier</AppBtn>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card right">
              <div class="content">
                <div class="d-flex justify-content-between">
                  <div>
                    <div class="d-flex align-items-center">
                      <div>
                        <span class="title-sm black">Payment Method</span>
                      </div>
                    </div>
                    <div>
                      <span class="text-xs muted">Change how you pay for your plan.</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <div class="d-flex flex-row">
                    <div class="mr-3">
                      <CardIcon :type="currentPaymentMethod.card.brand" width="64px" height="44px" />
                    </div>
                    <div class="d-flex flex-column">
                      <span class="title-sm black">{{ currentPaymentMethod.card.brand | capitalize }} ending {{ currentPaymentMethod.card.last4 }}</span>
                      <span class="title-xs muted">Expiry {{ currentPaymentMethod.card.exp_month }}/{{ currentPaymentMethod.card.exp_year }}</span>
                      <span class="d-flex title-xs muted">
                        <AppIcon name="email" class="mr-2"/>{{ currentUser.email }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <AppBtn @click="onEditPaymentMethodClick" text class="text-primary p-0">Edit Payment Method</AppBtn>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <div class="d-flex align-items-center">
                      <div>
                        <span class="title-xs black">Billing Period</span>
                      </div>
                    </div>
                    <div class="title-xs black">
                      Monthly
                      <span class="muted">(renews {{ currentSubscription.end_date }})</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="subscriptions-table">
          <h3>BILLING HISTORY</h3>
          <div class="d-none col-12 d-md-block">
            <AppTable :headers="headers" :items="billingHistory">
              <template v-slot:item="{ shownItem }">
                <td class="d-flex align-items-center">
                  {{ shownItem.invoice }}
                </td>
                <td class="">
                  {{ shownItem.amount }}
                </td>
                <td class="">
                  {{ shownItem.date }}
                </td>
                <td class="">
                  <div class="status-pill">
                    <img :src="require('@/assets/products/svg/DoubleCheckmark.svg')" />
                    <span>{{ shownItem.status }}</span>
                  </div>
                </td>
                <!-- <td class="">
                  <AppIcon name="document_download" class="text-info"/>
                </td> -->
              </template>
            </AppTable>
          </div>
          <div class="d-md-none">
            <AppTable :headers="headers" :items="subscriptionHistory" :itemsPerPage="8">
              <template v-slot:item="{ shownItem }">
                <td class="d-flex align-items-center">
                  {{ shownItem.invoice }}
                </td>
                <td class="">
                  {{ shownItem.amount }}
                </td>
                <td class="">
                  {{ shownItem.date }}
                </td>
                <td class="">
                  <div class="status-pill">
                    <img :src="require('@/assets/products/svg/DoubleCheckmark.svg')" />
                    <span>{{ shownItem.status }}</span>
                  </div>
                </td>
                <!-- <td class="">
                  <AppIcon name="document_download" class="text-info" />
                </td> -->
              </template>
            </AppTable>
          </div>
        </div>
      </template>

      <AppLoading fillSpace v-else-if="pageLoading"></AppLoading>

      <template v-else>
        <h3>NO CURRENT SUBSCRIPTION</h3>

        <div class="row">
          <div
            class="col-4"
            v-for="subscription in subscriptions"
            :key="subscription.id"
          >
            <SubscriptionCard
              :title="subscription.name"
              :price="Number(subscription.price)"
              :description="subscription.description"
            >
              <template v-slot:footer-bottom>
                <AppBtn @click="onSubscribeClick(subscription)" text class="text-primary p-0 mt-2" style="width: 78px;">Subscribe</AppBtn>
              </template>
            </SubscriptionCard>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <h3>BILLING HISTORY</h3>
          </div>
          <div class="col-2 offset-5 justify-content-center no-history">
            <div class="d-flex flex-column justify-content-center align-items-center text-center">
              <img :src="require('@/assets/products/svg/BillingHistory.svg')" />
              <p class="black title-sm mt-3">No billing history yet</p>
            </div>
          </div>
        </div>
      </template>

      <AppModal class="subscription-modal" width="500px" v-model="subscriptionModal">
        <div class="modal-header d-flex align-items-center justify-content-between">
          <h4 class="m-0">SUBSCRIPTION TIER</h4>
          <AppIcon
            hoverable
            name="close"
            size="24px"
            class="d-flex"
            @click="subscriptionModal = false"
          ></AppIcon>
        </div>

        <div class="d-flex flex-column content">
          <SubscriptionCard
            v-for="subscription in subscriptions"
            :key="subscription.id"
            :title="subscription.name"
            :price="Number(subscription.price)"
            :description="subscription.description"
            :isActive="!currentBill ? false : subscription.stripe_id === currentBill.stripe_id"
          />
        </div>
      </AppModal>
    </div>
  </LayoutVendor>
</template>

<script>
import AppTable from '@/shared/elements/AppTable.vue';
import { getSubscriptions, getSubscriptionHistory, getPaymentMethods } from '@/shared/api/Subscription';
import AppLoading from '@/shared/elements/AppLoading.vue';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppModal from '@/shared/elements/AppModal.vue';
import { DateTime } from 'luxon';

import CardIcon from '@/components/Partial/Subscriptions/CardIcon.vue';

import SubscriptionCard from '@/components/Partial/Subscriptions/SubscriptionCard.vue';

export default {
  name: 'Subscription',

  components: {
    AppTable,
    AppLoading,
    LayoutVendor,
    AppBtn,
    AppIcon,
    AppModal,
    SubscriptionCard,
    CardIcon,
  },

  mixins: [],

  data() {
    return {
      subscriptionModal: false,
      headers: [
        {
          text: 'Invoice',
          value: 'invoice',
          sortable: false,
        },
        {
          text: 'Amount',
          value: 'amount',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'date',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        },
        {
          text: ' ',
          value: ' ',
          sortable: false,
        },
      ],
      subscriptions: [],
      subscriptionHistory: [],
      paymentMethods: [],
      pageLoading: false,
    };
  },

  computed: {
    billingHistory() {
      return this.subscriptionHistory.map((s) => {
        return {
          stripe_id: s.plan.id,
          invoice: s.plan.nickname,
          amount: `$${s.plan.amount / 100}`,
          date: DateTime.fromSeconds(s.start_date).toLocaleString(DateTime.DATE_FULL),
          end_date: DateTime.fromSeconds(s.current_period_end).toLocaleString(DateTime.DATE_FULL),
          description: 'Our most popular plan for small teams.',
          status: 'Paid',
        };
      });
    },
    currentBill() {
      const { company } = this.currentUser;

      return company?.subscription || null;
    },
    currentSubscription() {
      return this.billingHistory.find((b) => b?.stripe_id === this.currentBill?.stripe_id);
    },
    currentPaymentMethod() {
      return this.paymentMethods[0] || null;
    },
    currentUser() {
      return this.$store.getters['auth/user'];
    },

    memberPercentage() {
      if (!this.currentBill) return 0;

      return ((this.currentUser.company.users_count / this.currentBill.max_user) * 100).toFixed(0);
    },
  },

  filters: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },

  methods: {
    async fetchSubscriptionHistory() {
      const { data } = await getSubscriptionHistory();

      this.subscriptionHistory = data.data;
    },

    async fetchSubscriptions() {
      const { data } = await getSubscriptions();

      this.subscriptions = data;
    },

    async fetchPaymentMethods() {
      const { data } = await getPaymentMethods();

      this.paymentMethods = data.data;
    },

    onSubscribeClick(subscription) {
      this.$router.push(`/subscription-checkout/${subscription.id}`);
    },

    onEditPaymentMethodClick() {
      this.$router.push(`/subscription-checkout/${this.currentBill.id}?edit=1`);
    },
  },

  async mounted() {
    this.pageLoading = true;
    await this.fetchSubscriptions();
    await this.fetchSubscriptionHistory();
    await this.fetchPaymentMethods();
    this.pageLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.subscriptions-page {
  min-width: 1100px;
  max-width: 1285px;
  margin: 0 auto;
}

.subscriptions-table {
  margin-top: 83px;
}

.subscription-modal {
  .content {
    padding: 0px 24px 40px 24px;
  }
}

.black {
  color: #282828;
}

.muted {
  color: #28282899;
}

.title-sm {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.title-xs {
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.card {
  background: #FFFFFF;

  border: 1px solid #CECECE;
  border-radius: 8px;

  padding-top: 20px;
  padding-bottom: 16px;

  .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.subscription-card {
  background: #FFFFFF;

  border: 1px solid #CECECE;
  border-radius: 8px;

  margin: 10px 0px;
  padding-top: 12px;

  .header,
  .footer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 16px;
  }
}

.pill {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #3F69FF;
  background: #D9E1FF;
  border-radius: 4px;
  padding: 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
}

.status-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  width: 75px;
  height: 28px;

  background: #DCEED8;
  border-radius: 2px;
  padding: 4px 12px;
  gap: 6px;

  color: #316723;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.progress {
  margin-top: 12px;
  margin-bottom: 32px;
  height: 8px;
  width: 100%;
}

.footer {
  border-top: 1px solid #CECECE;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
}

.modal-header {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  color: #282828;

  padding-bottom: 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid #F1F1F1;
}

.no-history {
  margin-top: 100px;
}
</style>
