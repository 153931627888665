// to be used
import axios from 'axios';
import { URL_PREFIX } from './variables';

export const getSubscriptions = (...rest) =>
  axios.get(`${URL_PREFIX}/subscription/list`, ...rest);

export const getSubscriptionHistory = (...rest) =>
  axios.get(`${URL_PREFIX}/subscription/check`, ...rest);

export const getStripeClientSecret = (...rest) =>
  axios.post(`${URL_PREFIX}/subscription/intent`, ...rest);

export const getPaymentMethods = (...rest) =>
  axios.get(`${URL_PREFIX}/subscription/cards`, ...rest);

export const updateDefaultPaymentMethod = (payment_method_id, ...rest) =>
  axios.post(`${URL_PREFIX}/subscription/cards/default`, { payment_method_id, ...rest });

export const subscribe = (subscription_id, ...rest) =>
  axios.post(`${URL_PREFIX}/subscription/subscribe`, { subscription_id, ...rest });

export const removePaymentMethod = (payment_method_id, ...rest) =>
  axios.post(`${URL_PREFIX}/subscription/cards/remove`, { payment_method_id, ...rest });

export default {
  getSubscriptions,
  getStripeClientSecret,
  getPaymentMethods,
  updateDefaultPaymentMethod,
  subscribe,
  getSubscriptionHistory,
  removePaymentMethod,
};
