<template>
  <div class="subscription-card">
    <div class="header">
      <div class="d-flex align-items-center">
        <img :src="require('@/assets/products/svg/Company.svg')" class="mr-2" />
        <span class="title-sm black">{{ title }}</span>
      </div>
    </div>
    <div class="footer">
      <div class="price title-sm black">
        ${{ price }}
        <span class="title-xs muted">/month</span>
      </div>
      <span class="title-xs muted">{{ description }}</span>
      <slot name="footer-bottom"></slot>
      <div v-if="isActive" class="active title-xs mt-3">
        <span>Current Plan</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionCard',
  props: {
    title: {
      type: String,
    },
    price: {
      type: Number,
    },
    description: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-card {
  background: #FFFFFF;

  border: 1px solid #CECECE;
  border-radius: 8px;

  margin: 10px 0px;
  padding-top: 12px;

  .header,
  .footer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 16px;
  }

  .footer {
    border-top: 1px solid #CECECE;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
  }

  .title-sm {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  .title-xs {
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  .black {
    color: #282828;
  }

  .muted {
    color: #28282899;
  }
}

.active {
  color: #52AC3B;
}
</style>